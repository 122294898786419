import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"


export default function CareerStructuredMarkup({
  careerMarkup,
  title
}) {
  const dateposted = careerMarkup.dateposted
  const directapply = careerMarkup.directapply
  const employmenttype = careerMarkup.employmenttype
  const monthsofexperience = careerMarkup.monthsofexperience
  const {maxvalue, minvalue, unittext} = careerMarkup.salaryGroup
  const validthrough = careerMarkup.validthrough
  const postContent = careerMarkup.postContent

  return (
    <StaticQuery
      query={graphql`
        query OptionsData {
          wp {
            myOptionsPage {
              acf_options {
                addressGroup {
                  addresscountry
                  addresslocality
                  addressregion
                  postalcode
                  streetaddress
                }
                organizationImage {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Helmet>
          <script type="application/ld+json">
            {`
                {
                  "@context" : "https://schema.org/",
                  "@type" : "JobPosting",
                  "directApply":${directapply},
                  "title" : "${title}",
                  "description" : "${postContent.replace(/(\r\n|\n|\r)/gm, "").replaceAll('"', "'")}",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Edge45 Limited",
                    "value": "001"
                  },
                  "experienceRequirements" : {
                    "@type" : "OccupationalExperienceRequirements",
                    "monthsOfExperience" : "${monthsofexperience}"
                  },
                  "datePosted" : "${dateposted}",
                  "validThrough" : "${validthrough}",
                  "employmentType" : "${employmenttype}",
                  "hiringOrganization" : {
                    "@type" : "Organization",
                    "name" : "Edge45 Limited",
                    "sameAs" : "https://edge45.co.uk",
                    "logo" : "https://edge45.co.uk${data.wp.myOptionsPage.acf_options.organizationImage.localFile.publicURL}"
                  },
                  "jobLocation": {
                    "@type": "Place",
                    "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "${data.wp.myOptionsPage.acf_options.addressGroup.streetaddress}",
                    "addressLocality": "${data.wp.myOptionsPage.acf_options.addressGroup.addresslocality}",
                    "addressRegion": "${data.wp.myOptionsPage.acf_options.addressGroup.addressregion}",
                    "postalCode": "${data.wp.myOptionsPage.acf_options.addressGroup.postalcode}",
                    "addressCountry": "${data.wp.myOptionsPage.acf_options.addressGroup.addresscountry}"
                    }
                  },
                  "baseSalary": {
                    "@type": "MonetaryAmount",
                    "currency": "GBP",
                    "value": {
                      "@type": "QuantitativeValue",
                      "minValue": ${minvalue}.00 ,
                      "maxValue": ${maxvalue}.00 ,
                      "unitText": "${unittext}"
                    }
                  }
                }
            `}
          </script>
        </Helmet>
        
      )}
    />
  )
}